@import "src/global-styles/colors";
@import "src/global-styles/typography";
@import 'src/mixins/_mixins';

.faceCardContainerv1 {
    background: $gray--lightthree;
    padding: 38px 60px;
    margin-bottom: 25px;
    border: 1px solid $gray--dark;

    &__sliderCounter {
        display: flex;
        justify-content: center;
        font-size: 23px;
        padding-bottom: 20px;
    }

    &__slider {
        position: relative;

        .slick-slider {

            .slick-prev.slick-disabled,
            .slick-next.slick-disabled {
                display: block;
                opacity: 0.5;
            }
        }

        .slick-arrow {
            bottom: auto;
            top: 43%;
            margin-top: -12px;
            background-color: transparent;
            border-radius: 15px;
            padding: 0;
            font-size: 10px;
            color: $white;

            .arrow {
                background-size: contain;
                height: 100px;
                width: 28px;
                position: relative;


                &.left {
                    background: url("../../assets/images/vector_left.svg") no-repeat;
                    right: 41px;
                }

                &.right {
                    background: url("../../assets/images/vector_right.svg") no-repeat;
                    left: 49px;
                }

            }

        }

        .slick-prev {
            left: 1px;
            right: auto;
            line-height: 0;
            height: 0;
        }

        .slick-next {
            right: 1px;
            line-height: 0;
            height: 0;
        }
    }

    &__title {
        font-size: 34px;
        color: $bcggreenthree;
        margin-bottom: 40px;
        word-wrap: break-word;
    }

    &__slide {
        display: flex;
        column-gap: 70px;
        row-gap: 25px;
        flex-wrap: wrap;

        &__item {
            text-align: center;
            color: $gray-darktwo;
            width: 150px;
            height: 230px;

            &__image {

                margin-bottom: 20px;
                border: solid 3px $bcggreenthree;
                width: 126px;
                height: 126px;

                &-greyscale {
                    img {
                        filter: grayscale(100%);
                    }
                }

                img {
                    width: 121px;
                    height: 121px;
                    border-radius: inherit;
                }
            }

            &__image.circle {
                border-radius: 50%;
            }

            &__image.square {
                border-radius: 0;
            }

            &__name {
                font-size: 18px;
                margin-bottom: 5px;
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;
                color: $bcggreenthree;
                text-align: left;
            }

            &__title {
                font-size: 14px;
                margin-bottom: 5px;
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;
                text-align: left;
            }

            &__location {
                font-size: 14px;
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;
                text-align: left;
            }
        }
    }
}