@import "src/global-styles/colors";
@import "src/global-styles/typography";
@import "src/mixins/mixins";

.FCG_ProposalBio {
	background-color: $gray--light;
	padding: 0 0 20px 20px;

	&__header {
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 20px 0px 10px 0px;
	}

	&__title-wrapper {
		display: flex;
		align-items: center;
	}

	&__title {
		padding-right: 24px;

		& > h2 {
			margin: 0;
			font-size: 20px;
			color: $bcg-purple--dark;
			font-family: $sans-bold;
		}
	}

	&__changeBtn {
		background: none;
		padding: 5px 10px;
		border: 1px solid $bcggreenfive;
		color: $bcggreenfive;
		font-size: 16px;
		font-family: $sans-reg;
		cursor: pointer;
		height: 34px;
	}

	&__closeBtn {
		background: none;
		display: flex;
		align-items: center;
		justify-content: center;
		padding: 8px 16px;
		border: none;
		color: $bcg-purple--dark;
		font-size: 12px;

		& > span {
			margin-right: 7px;
		}

		& > img {
			width: 22px;
			height: 22px;
			vertical-align: middle;
		}
	}

	&__slideTitle {
		position: relative;
		display: inline-block;
	}

	&__slideTitleCounter {
		position: absolute;
		right: 88px;
		top: 38px;
		width: 39px;
	}

	&__slideTitleCheckboxes {
		margin-right: 120px;
		margin-top: 44px;
		display: flex;
		justify-content: space-between;
		align-items: center;
	}

	&__slideText {
		color: $blackfour;
		font-size: 14px;
		font-family: $sans-reg;
		padding-bottom: 5px;
	}

	&__inputTitle {
		border: 0.5px solid $gray--lighttfour;
		margin-right: 63px;
		padding-left: 10px;
		font-family: $sans-reg;
		width: 512px;
		height: 48px;
		outline: none;
		color: $blackfour;
		padding-right: 74px;

		&::placeholder {
			opacity: 0.4;
			font-family: $sans-reg;
			font-size: 14px;
		}
	}

	&__blackWhiteToggle {
		display: flex;
		align-items: center;
		gap: 30px;

		&__shapeToggle {
			display: flex;
			flex-direction: column;
			padding: 20px;

			.toggleSwitch.ui.fitted.toggle.checkbox {
				border-color: $bcg-green--dark;

				input:checked ~ label:before {
					background-color: $bcg-green--dark !important;
				}

				label:after {
					border-color: $bcg-green--dark;
				}
			}

			span {
				font-size: 14px;
				color: $blackfour;
				font-family: $sans-reg;
			}

			&__shapeOptions {
				display: flex;

				&__circle {
					padding-right: 5px;
				}

				&__square {
					padding-left: 5px;
				}
			}
		}

		&__bwToggle {
			display: flex;
			flex-direction: column;
			margin-left: 18px;

			span {
				font-size: 14px;
				color: $blackfour;
				font-family: $sans-reg;
				margin-right: 8px;
			}

			.toggleSwitch.ui.fitted.toggle.checkbox {
				border-color: $bcg-green--dark;

				input:checked ~ label:before {
					background-color: $bcg-green--dark !important;
				}

				label:after {
					border-color: $bcg-green--dark;
				}
			}
		}
	}

	&-header {
		display: flex;
		justify-content: space-between;
		align-items: flex-start;
		width: 100%;
	}

	&__caseinput {
		border: none;
		outline: none;
		width: calc(100% - 175px);
		height: 43px;
	}

	&__loaderPeoples {
		width: 30px;
		margin: 16px 0px 0px 42px;
	}

	&__loaderPeoplesCase.image {
		width: 30px;
		position: absolute;
		top: 18px;
		left: 157px;
	}

	&__resetbtn {
		text-align: right;
		margin-bottom: 5px;
		position: absolute;
		top: 10px;
		right: 0px;

		.ui.button {
			background: url("../../assets/images/reset.svg") no-repeat 18px center;
			padding-left: 35px;
			border: none;

			& > span {
				text-transform: none;
				font-family: $sans-reg;
				font-size: 14px;
				color: $bcggreenfive;
			}

			&.green-transparent:hover {
				padding-left: 35px;
				background-image: url("../../assets/images/reset-black.svg");

				& > span {
					color: $white;
				}

				border: 1px solid $bcggreenfive;
				background-color: $bcggreenfive;
			}

			&.disabled {
				// background-image: url("src/assets/images/reset.svg") !important;
				cursor: not-allowed;
				pointer-events: initial !important;

				& > span {
					color: $bcggreenfive;
				}

				border: none;
				background-color: transparent;

				&:hover {
					background-color: transparent;

					& > span {
						color: $bcggreenfive;
					}

					border: none;
				}
			}
		}
	}

	&__tabsComponent {
		.ui.secondary.pointing.menu {
			border-color: $bcggreenfive; //gray--dark
			border-bottom-width: 1px;
			margin-bottom: 2.5px;
			width: 487px;

			.item {
				padding-left: 21px;
				padding-right: 21px;
				border-top: 3px solid transparent;
				margin-left: 10px;
				margin-right: 10px;
				align-self: auto;

				&.active {
					&::before {
						background: url("../../assets/images/vector_search_green.svg") no-repeat;
						display: inline-block !important;
						content: " ";
						height: 20px;
						width: 23px;
						vertical-align: text-bottom;
						top: 12px;
						left: 11px;
						background-size: 14px;
						background-position: center;
					}

					@extend %item-active;

					&:hover {
						border-top: 3px solid $bcggreenfive;
					}
				}

				&:hover {
					border-top: 3px solid $bcg-green--dark;
					//border-top-left-radius: 20px;
				}
			}
		}

		.ui.attached.bottom.segment {
			//border: 0.5px solid $gray--lighttfour;
			padding: 0px;
			position: relative;
			border-top: 0px;
		}
	}

	&__leftsec {
		display: flex;
		align-items: center;
	}

	&__dropdown {
		margin-right: 10px;
		display: inline-block;
		margin-left: 8px;
		width: 150px;
		vertical-align: top;

		// &--casetype {

		// }

		.ui.dropdown.fluid {
			border: 2px solid $bcggreenfive;
			border-radius: 20px;

			& .menu {
				border: 2px solid $bcggreenfive;
				margin: 0 -2px;
				width: calc(100% + 4px);
			}

			> .text {
				font-family: $sans-reg;
				font-size: 14px;

				&::before {
					background: url("../../assets/images/search.svg") no-repeat;
					content: " ";
					height: 20px;
					display: inline-block;
					width: 20px;
					vertical-align: text-bottom;
					margin-right: 10px;
				}
			}
		}

		.search.icon {
			&::before {
				display: inline-block;
				width: 12px;
				height: 12px;
				border-top: 3px solid $bcggreenfive;
				border-right: 3px solid $bcggreenfive;
				content: "";
				transform: rotate(135deg);
				// transform: rotate(-45deg);
			}
		}
	}

	&__peopleselector {
		padding-bottom: 0px;
		width: 487px;
		height: 66px;
		border: 1px solid $gray--lighttfour;
		border-radius: 0px;

		&.peopleselector__wrapper > .react-autosuggest__container {
			width: calc(100% - 170px);
			display: inline-block;

			> .react-autosuggest__input {
				margin-left: 12px;

				&::placeholder {
					font-family: $sans-reg;
					font-size: 1px;
					color: $gray--lightsix;
					margin-top: 3px;
				}

				&:focus {
					border: 0;
					outline: none;

					&::placeholder {
						color: $bcg-light-gray;
					}
				}
			}
		}

		&.peopleselector__wrapper--dragDrop {
			overflow: visible;
			border: 1px solid $gray--lighttfour;
			border-radius: 0px;
		}
	}

	&-searchSec {
		margin-top: 23px;
		position: relative;

		& > .peopleselector__wrapper > .react-autosuggest__container {
			width: calc(100% - 170px);
			display: inline-block;

			> .react-autosuggest__input {
				height: 43px;
				width: 100%;
				margin-left: 12px;
				margin-top: 4px;

				&:focus {
					border: 0;
					outline: none;
				}
			}

			.react-autosuggest__suggestions-container--open {
				margin-top: -1px;
			}

			.react-autosuggest__input::placeholder {
				font-family: sans-serif;
				font-size: 1.16em;
				color: $gray--lightsix;
				margin-top: 3px;
			}

			.react-autosuggest__input:focus::placeholder {
				color: $bcg-light-gray;
			}
		}

		.peopleselector {
			&__infoCard {
				overflow-x: hidden;
				display: block;
			}
		}
	}

	&-btnSec {
		align-items: center;
		width: 197px;
		margin-top: 25px;
		position: relative;

		.ui.image.loadericon {
			width: 30px;
			position: absolute;
			left: -30px;
			top: 31px;
		}

		.ui.button.previewBtn {
			height: 40px;
			justify-content: center;
			margin-bottom: 10px;
			padding: 0;
			margin-left: 6px;
			align-items: center;
			background-color: transparent;
			border: none;

			& > span {
				padding: 13px 19px;
				color: $bcggreenfive;
				font-size: 14px;
				font-family: $sans-reg;
				text-transform: none;
				border: 1px solid $bcggreenfive;
				height: 54%;
				width: 100%;

				&:hover {
					color: $white;
					background-color: $bcggreenfive;
				}
			}

			&.btn-disbled {
				cursor: not-allowed;
				pointer-events: initial !important;

				border: none;
				margin-bottom: 8px;

				& > span {
					&:hover {
						background-color: transparent;
						color: $bcggreenfive;
					}
				}
			}
		}

		.ui.button.downloadBtn {
			text-transform: none;
			font-family: $sans-reg;
			width: 162px;
			font-size: 14px;
			display: flex;
			height: 40px;
			justify-content: center;
			align-items: center;
			background: $bcggreenfive;
			border-radius: 0;
			margin: 0 6px;

			& > span {
				color: $white;
				padding-left: 25px;
				background: url("../../assets/images/fcg_download.svg") no-repeat 0px center;
			}

			&.btn-disbled {
				cursor: not-allowed;
				pointer-events: initial !important;

				&:hover {
					background: $bcggreenfive;
				}
			}
		}
	}

	&__checkboxTitlee {
		margin-left: 18px;
	}

	&__checkboxTitle {
		color: $blackfour;
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		margin-top: 20px;
		margin-left: 4px;
		padding-bottom: 2px;
	}

	&-checkboxSec {
		display: flex;
		justify-content: space-between;
		align-items: center;
		width: 76%;

		.custom__checkbox.ui.checkbox {
			input:checked ~ label {
				&::before {
					background-color: #197a56;
					border-color: #197a56;
				}

				&::after {
					background-color: #197a56;
				}
			}
		}

		&--text {
			color: $blackfour;
			font-family: $sans-reg;
			font-size: 14px;
			font-style: normal;
			font-weight: 700;
			line-height: 18px;
			margin: 0 5px;
		}

		&--title,
		&--location,
		&--name {
			margin: 0 5px;

			& > .custom__checkbox.ui.checkbox label {
				color: $blackfour;
				/* Body/Regular/Body Large Regular */
				font-family: $sans-reg;
				font-size: 14px;
				font-style: normal;
				font-weight: 400;
				line-height: 24px;
				padding-left: 22px;
				top: 1px;
			}
		}

		&--divider {
			margin: 0 5px;
			width: 1px;
			height: 17px;
		}

		&--toggle {
			display: flex;
			justify-content: center;
			align-items: center;

			& > span {
				margin: 0 10px;
			}
		}

		.toggleSwitch.ui.fitted.toggle.checkbox {
			border-color: yellow;
		}
	}

	&__error {
		min-height: 30px;
		display: inline-block;

		img.ui.image {
			display: inline-block;
			margin-right: 10px;
		}

		&--msg {
			position: absolute;
			@include errorMsg;
		}
	}

	&__disclaimer {
		padding-top: 10px;

		img.ui.image {
			display: inline-block;
			margin-right: 10px;
			vertical-align: top;
		}
	}

	.searchBoxWrapper {
		width: calc(100% - 6px);
		position: static;
		display: inline-block;

		&__searchForm.ui.form {
			& > .fields {
				display: block;
				margin-bottom: 0px !important;
			}
		}

		&__input {
			.ui.input input {
				border: none;
				padding: 12px;
			}
		}

		&__results {
			top: 63px;
		}
	}

	.emailInputWrapper {
		width: calc(100% - 6px);
		position: static;
		display: inline-block;

		&emailInput.ui.form {
			& > .fields {
				display: block;
				margin-bottom: 0px !important;
			}
		}

		&__input {
			.ui.input input {
				border: none;
				padding: 12px;
				margin-left: -10px;
			}

			&--error {
				.ui.input {
					input {
						border: 1px solid $notify-warn-highlight;
					}
				}
			}
		}

		&__error p {
			margin-left: 0px;
		}
	}
}