@import 'src/global-styles/colors';
@import 'src/global-styles/typography';
@import 'src/global-styles/responsive';

.layout_kpconsumption {
  padding: 0px;
  margin: 0px;
  background-color: $white;
  width: 100%;
  margin-top: -10px;

  &__twoCols {
    display: flex;
    max-width: 1567px;
    position: relative;

    &-leftsec {
      width: calc(65% - 30px);

      .csdescription {
        min-height: 375px;
        padding-right: 0;
        margin-bottom: 40px;

        &__col {
          max-width: 100%;
        }
      }
    }

    &-rightsec {
      width: 35%;
      position: absolute;
      height: calc(100% + 90px - 30px);
      right: 0;
      top: -90px;
      bottom: 0;

      .csdescription {
        height: 100%;
      }
    }


    .ui.bottom.attached.segment.active.tab {
      padding-right: 20px;
    }

    .cardslayout__wrapper.ui.four.cards {
      margin: 0;
      margin-left: -12px;
    }

    .customizedCard {
      &__location {
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }

      &__wrap {
        width: calc(33.33% - 15px);
        margin-left: 14px;
        margin-top: 14px;

        @media screen and (max-width: 1220px) {
          width: calc(50% - 15px);
        }
      }

      &.ui.card.cardhover {
        width: 100%;
        margin: 0;
      }
    }

    .kent__col2 {
      margin-right: 0px;
    }
  }

  &__topbar-color {
    background-color: $gray--darker;
  }

  &__tabs {
    background-color: $gray--lightest;

    .ui.secondary.pointing.menu .item {
      padding: 13px 5px;
      margin-right: 15px;
    }

    .taxonomylisting {
      margin-top: -12px;
    }
  }

  &__divider {
    width: 93%;
    margin-left: 50px;
    margin-top: 10px;
    margin-bottom: 10px;
    box-sizing: border-box;
    height: 1px;
    display: block;
    border-top: 1px solid $gray--dark;
  }

  .footer-workflow {
    &.btns-count-is-1 {
      .ui.buttons {
        margin-left: auto;
      }
    }
  }
}