@import 'src/global-styles/colors';
@import 'src/global-styles/typography';
@import "src/mixins/mixins";


.faceCardTemplate {

    &__rowContainer {
        margin-bottom: 30px;
    }

    &__templateMessage {
        color: $bcg-purple;
        font-size: 20px;
        font-family: $sans-bold;
        text-align: left;
        width: 100%;
        padding-left: 3px;
        padding-bottom: 10px;
    }

    &__gridContainer {
        background-color: $gray--light;
        padding-top: 11px;

        p {
            color: $bcg-purple;
            font-size: 16px;
            font-family: $sans-reg;
            text-align: left;
            padding-left: 15px;
        }

    }

    &__templatesGrid {
        display: flex;
        width: 100%;
        padding-left: 16px;
    }

    &__templateSection {
        flex-direction: column;
        align-items: center;
        padding: 0px 10px 5px 10px;
        box-sizing: border-box;
    }

    &__imageContainer {
        cursor: pointer;
    }

    &__gridBox {
        width: 175px;
        height: 116px;
        background-color: $white;
        padding: 9px;

        &:hover {
            cursor: pointer;

            .fcgGridTemplate__block {
                background-color: $bcg-purple--light;
                opacity: 20%;
                cursor: pointer;
            }
        }
    }

    &__templateLabel {
        color: $bcg-purple--mlight;
        font-size: 14px;
        font-family: $sans-bold;
        padding: 8px 0 2px;
        text-align: center;
        width: 100%;
        display: block;
        margin: 0;
    }
}