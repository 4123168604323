@import 'src/global-styles/typography';

.banner {
  background: url("../../assets/images/results-background.jpg");
  background-repeat: no-repeat;
  height: 105px;
  background-size: 100%;
  position: fixed;
  z-index: 1004;
}

.page-header {
  width: 100%;

  &__left {
    margin-bottom: 20px;
    margin-left: 30px;
  }

  &__right {
    float: right;
    margin-right: 30px;
    margin-top: 20px;
  }

  &__bottom {
    clear: both;
    margin: 20px 0 0;
  }
}